import React from "react"
import { graphql } from 'gatsby'

import { Container, Col, Row, Image } from "react-bootstrap"

import logoResearchGate from "../images/researchgate_icon_130843.png"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Integrantes({ data }) {
  const post = data.nodeIntegrantes
  
  return (
    <Layout>
      <br />
      <h1 id="texttitleLayout" className="mx-auto my-0 cssanimation sequence fadeInBottom">{post.title}</h1>
      <br />
      <SEO title={post.title} />
      <Container className="">
        <h1 class="text-center">{post.field_nombre} {post.field_apellido} ({post.relationships.field_categoria.name}) <Image src={"https://dev-openresearchlab.pantheonsite.io/" + post.relationships.field_pais.relationships.field_bandera.uri.url} style={{ width: `60px`, height: `60px` }} alt={post.field_nombre} /></h1>
        <Row>
          <Col>
            <Image src={"https://dev-openresearchlab.pantheonsite.io/" + post.relationships.field_fotografia.uri.url} alt={post.field_nombre} fluid />
            
          </Col>
          <Col sm={8}>
            <div class="text-justify" dangerouslySetInnerHTML={{ __html: post.body.value }}></div>
            <Row>
              <Col sm={10}>
                {!!(post.field_researchgate) ? <a href={post.field_researchgate} rel="noreferrer" target="_blank">{post.field_researchgate}  <Image className="img-fluid" src={logoResearchGate} style={{display:`initial`}}></Image></a> : null}
              </Col>
            </Row>
          </Col>
        </Row>


      </Container>
    </Layout>
  )
}
export const query = graphql`
    query($id: String!) {
        nodeIntegrantes(id: { eq: $id }) {
          field_nombre
        field_apellido
        id
        path {
          alias
        }
        field_researchgate
        body {
          value
        }
        relationships {
          field_fotografia {
            uri {
              url
            }
          }
          field_categoria {
            name
          }
          field_campus {
            name
          }
          field_pais {
            relationships {
              field_bandera {
                uri {
                  url
                }
              }
            }
          }
        }
      }
    }
  `